<template>
    <div class="page">
        <!-- 下载按钮页 -->
        <div class="app-download">
            <!-- 口号 -->
            <div style="padding-top: 50px; text-align: center">
                <el-image
                    src="https://list-1300315497.cos.ap-beijing.myqcloud.com/web-pc/appDownload/1-1.png"
                    class="img-slogan"/>
            </div>

            <!-- 下载按钮 -->
            <div
                style="padding: 50px 20px 20px 20px; display: flex; justify-content: center; align-content: center;">
                <!-- 安卓 -->
                <div v-if="!isMobile || isAndroid" class="img-down">
                    <el-image
                        @click="handleDownload(0)"
                        src="https://list-1300315497.cos.ap-beijing.myqcloud.com/web-pc/appDownload/1-2.png"
                        class="img-down-img"/>
                </div>

                <!-- IOS -->
                <div v-if="!isMobile || isIOS" class="img-down">
                    <el-image
                        @click="handleDownload(1)"
                        src="https://list-1300315497.cos.ap-beijing.myqcloud.com/web-pc/appDownload/1-3.png"
                        class="img-down-img"/>
                </div>
            </div>

            <!--  展示页面  -->
            <div style="text-align: center">
                <el-image
                    src="https://list-1300315497.cos.ap-beijing.myqcloud.com/web-pc/appDownload/1-4.png"
                    class="img-show"/>
            </div>
        </div>

        <!--其他页-->
        <div>
            <!-- 2 -->
            <el-image
                src="https://list-1300315497.cos.ap-beijing.myqcloud.com/web-pc/appDownload/2.jpg"
                class="img-card"/>
            <!-- 3 -->
            <el-image
                src="https://list-1300315497.cos.ap-beijing.myqcloud.com/web-pc/appDownload/3.jpg"
                class="img-card"/>

            <!-- 4 -->
            <el-image
                src="https://list-1300315497.cos.ap-beijing.myqcloud.com/web-pc/appDownload/4.jpg"
                class="img-card"/>

            <!-- 5 -->
            <el-image
                src="https://list-1300315497.cos.ap-beijing.myqcloud.com/web-pc/appDownload/5.jpg"
                class="img-card"/>

            <!-- 6 -->
            <el-image
                src="https://list-1300315497.cos.ap-beijing.myqcloud.com/web-pc/appDownload/6.jpg"
                class="img-card"/>

            <!-- 7 -->
            <el-image
                src="https://list-1300315497.cos.ap-beijing.myqcloud.com/web-pc/appDownload/7.jpg"
                class="img-card"/>
        </div>

        <!-- 微信提示页 -->
        <div v-if="isShowWxTips" class="wxShowTips">
            <el-image
                :src="require('@/assets/images/wx-show2browser-tips.png')"
                style="width: 100%; height: 100%"/>
        </div>

    </div>
</template>

<script>
export default {
    name: "appDownload",
    data() {
        return {
            isShowWxTips: false, // 是否展示微信下载环境提示
            isMobile: false, // 是否是手机
            isIOS: false, // 是否是IOS
            isAndroid: false, // 是否是安卓
        }
    },
    mounted() {
      // 判断操作系统
        const ua = navigator.userAgent.toLowerCase();
        const isMobile = /android|webos|iphone|ipad|ipod|ios|blackberry|iemobile|opera mini/i.test(ua);
        if (isMobile) {
            this.isMobile = true

            if (/(iphone|ipad|ipod|ios)/i.test(ua)) {
                //Ios
                this.isIOS = true
            } else if (/(android)/i.test(ua)) {
                //Android终端
                this.isAndroid = true
            }
        }
    },
    methods: {
        // 点击下载按钮
        handleDownload(type) {
            // 判断是否是微信环境
            let ua = window.navigator.userAgent.toLowerCase();
            // 判断是否是微信浏览器环境
            if ((/MicroMessenger/i).test(ua)) {
                this.isShowWxTips = true
            } else {
                this.$api.get("/about/app/getAppDownloadInfo", {
                    params: {
                        type: type
                    }
                }).then(res => {
                    console.log(res)

                    // 点击安卓下载
                    if (type === 0) {
                        const a = document.createElement('a')
                        const event = new MouseEvent('click')
                        a.href = res.data
                        a.dispatchEvent(event)
                    } else {
                        // 点击IOS下载
                        // itms-apps://itunes.apple.com/app/id   [414478124]   ?action=write-review
                        const a = document.createElement('a')
                        const event = new MouseEvent('click')
                        a.href = 'https://itunes.apple.com/app/id1486999489?mt=8'
                        a.dispatchEvent(event)
                    }
                })

            }
        }
    }
}
</script>

<style scoped lang="scss">

.page {
    max-width: 1200px;
    margin: 0 auto;
}

.app-download {
    background: url("https://list-1300315497.cos.ap-beijing.myqcloud.com/web-pc/appDownload/1.jpg") no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 530px;

    .img-slogan {
        width: 80%;
    }

    .img-down {
        display: flex;
        align-content: center;
        justify-content: center;

        .img-down-img {
            max-width: 200px;
        }
    }

    .img-show {
        width: 60%;
    }
}

.img-card {
    width: 100%;
    height: 100%;
}

.wxShowTips {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: #9a9999;
}
</style>